// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'

import { aliases, fa } from 'vuetify/iconsets/fa-svg'
import { config } from '@/config'
import { setProduct } from '@/theme'

const product = setProduct(window.location.hostname) || 'clicsante'
const defaultTheme = {
  primary: '#003E6C',
  secondary: '#00D2E6',
  accent: '#6fa7fc',
  error: '#cc0a00',
  info: '#eaf4ff',
  success: '#4caf50',
  warning: '#ff8500',
  ga4: 'G-F5V68LLK4T'
}
const theme = config ? config[product] : defaultTheme
export const vuetifyThemeOpts = {
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa
    }
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          primary: theme.primary,
          secondary: theme.secondary,
          accent: theme.accent,
          error: theme.error,
          info: theme.info,
          success: theme.success,
          warning: theme.warning,
          'new-primary': '#5ECEFF'
        }
      },
      dark: {
        dark: true,
        colors: {
          primary: theme.primary,
          secondary: theme.secondary,
          accent: theme.accent,
          error: theme.error,
          info: theme.info,
          success: theme.success,
          warning: theme.warning,
          'new-primary': '#5ECEFF'
        }
      }
    }
  }
}

export default createVuetify({
  ...vuetifyThemeOpts
})
